/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import '~swiper/scss';
@import '~@ionic/angular/css/ionic-swiper';

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.slider-menu-list {
    padding: 0 !important;
    border-bottom: none !important;
}

.bg-gradient {
    // background: rgb(156,180,172);
    --background: linear-gradient(180deg, rgba(156,180,172,1) 0%, rgba(94,130,118,1) 100%)!important;
}

ion-split-pane { 
    --border: none !important;
    ion-menu {
     --background: var(--ion-color-primary) !important; 
        ion-content {            
            --background: var(--ion-color-primary) !important; 
            ion-list-header { 
                --background: var(--ion-color-primary) !important;
                --color: #FFFFFF !important;   
            }
            ion-item {
                --background: var(--ion-color-primary) !important;
                --color: #FFFFFF !important;
                 ion-icon {
                    color: #FFFFFF !important; 
                }
            }
            ion-item.selected {
                --background: var(--ion-color-success) !important;
                --color: #FFFFFF !important;
                 ion-icon {
                    color: #FFFFFF !important; 
                }
            }
        }
    }
}

ion-footer {
    ion-segment-button {
        min-width: 64px;
    }
}